import {
  ExitPreview,
  Layout,
  getAllSettings,
  isDarkMode,
  renderSections,
} from '@web-creditea/utils';
import { createClient } from '../lib/apollo-client';
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { FAQ_TRANSLATION, MARKET, REVALIDATION_TIME, SANITY_CDN_IMAGE_URL } from '../src/constants';
import { buildSanityImageUrl } from '@web-creditea/utils';

import { getHomePageData } from '@web-creditea/utils';
import { getDataset } from '../src/utils/helpers/getDataset';

const HomePage = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
  const {
    title,
    CTAButton,
    promoBarItem,
    sections,
    seoGroup,
    preview,
    overWriteCtaButton,
    mode,
    settings,
    dataset,
  } = props;

  const urlFor = buildSanityImageUrl({
    dataset,
    cdnUrl: SANITY_CDN_IMAGE_URL,
    projectId: process.env.SANITY_PROJECT_ID,
  });
  const { kubernetesNamespace } = settings;

  return (
    <Layout
      promoBarItem={promoBarItem}
      title={title}
      seo={seoGroup}
      ctaButton={overWriteCtaButton && CTAButton}
      settings={settings}
      mode={mode}
    >
      <>
        {preview ? <ExitPreview /> : null}
        {sections.length > 0 &&
          renderSections(
            sections,
            FAQ_TRANSLATION,
            MARKET,
            settings.downloadAppButtonsTitle,
            kubernetesNamespace,
            promoBarItem,
            urlFor
          )}
      </>
    </Layout>
  );
};

export default HomePage;

export const getStaticProps = async ({ locale, preview }: GetStaticPropsContext) => {
  const language = locale?.toUpperCase();
  const client = createClient();

  const pageData = await getHomePageData(client)(language, MARKET, preview);
  if (!pageData) {
    return { notFound: true };
  }
  const mode = isDarkMode(pageData?.sections);

  const settings = await getAllSettings(client, MARKET, language, preview);

  return {
    props: {
      ...pageData,
      mode,
      preview: Boolean(preview),
      settings,
      dataset: getDataset(),
    },
    revalidate: REVALIDATION_TIME,
  };
};
